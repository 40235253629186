import { text as t } from 'shared/text'

export const sourcesLayout = {
    id: 'sources',
    page: t.sources,

    tableHeaders: {
        id: t.sourceId,
        name: t.name,
        url: t.url,
        filename: t.fileName,
        updateSourceType: t.sourceType,
        terminalTypeDescription: t.terminalTypeDescription,
        environmentName: t.environment
    },

    sizes: {
        id: 'small',
        name: 'mid',
        url: 'large',
        filename: 'large',
        updateSourceType: 'mid',
        terminalTypeDescription: 'large',
        environmentName: 'mid'
    },

    layouts: {
        id: 'text-left',
        name: 'text-left',
        url: 'text-left',
        filename: 'text-left',
        terminalTypeDescription: 'text-left',
        environmentName: 'mid'
    },

    navigation: {
        id: {
            label: 'id',
            data: [
                {type: 'text', val: 'source'},
                {type: 'prop', val: 'id'},
            ]
        }
    }
}
