import { text as t } from 'shared/text';

export const companyUsersLayout = {
    id: 'companyUsers',

    headers: {
        company: t.company,
        users: t.users
    },

    tableHeaders: {
        emailAddress: t.email,
        roleDescription: t.role,
    },

    sizes: {
        emailAddress: 'large',
        roleDescription: 'mid',
    },

    layouts: {
        emailAddress: 'text-left',
        role: 'text-left',
    },

    navigation: {
        emailAddress: {
            label: 'emailAddress',
            data: [
                {type: 'text', val: 'user'},
                {type: 'prop', val: 'userId'},
            ]
        },
    }
}
