import '../Devices/devices.scss';
import { useEffect, useRef, useState } from 'react';
import { devicesMonitorsLayout as layout } from './DevicesMonitorsLayout';
import { filterDevicesByValue, } from 'shared/utils';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const DevicesMonitors = () => {
    const { devicesTabs } = useProfile();
    const { callAPI, hasRank, updateDevicesTabs } = useUtils();
    const [ tableData, setTableData ] = useState();
    const [ tableLoading, setTableLoading ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('devices', devicesListCallback);
        updateDevicesTabs();
    }, [])

    const devicesListCallback = (data) => {
        setTableLoading(false);
        setTableData( data?.list ? filterDevicesByValue(data.list, 5 ) : []); // 5 for Monitors
    }

    return (
        <Page
            page={t.devices}
            subPage={t.monitors}
            tabs={devicesTabs}
        >
            <Table
                data={tableData}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
                editable={hasRank('locationManager')}
            />
        </Page>
    )
}

export default DevicesMonitors;
