import './companyPerformanceReports.scss';
import { useEffect, useRef, useState } from 'react';
import { companyPerformanceReportsLayout as layout } from './CompanyPerformanceReportsLayout';
import { getDefaultDateRange } from 'components/shared/componentUtils';
import { reportsData } from 'shared/data';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TablePaged from 'components/Tables/TablePaged';
const tableId = 'companyPerformanceReports';
const companyId = 'CompanyId';
const terminalTypeId = 'TerminalTypeId' // TODO: add in if requested

const CompanyPerformanceReports = () => {
    const { inputValues, updateDropdown } = useInputs();
    const { tableDropdowns, updateDropdowns, 
            updateTable, getURLParams } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ masked, setMasked ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        callAPI('companies', companiesCallback);
    }, []);

    useEffect(() => {
        if (inputValues?.[reportsData.dropdownId] && companies){
            const urlParams = getURLParams(tableId);
            if (urlParams.dropdowns) {
                const sorts = urlParams.dropdowns?.[companyId];
                sorts && updateDropdown(companyId, sorts.value, sorts.label);
            }
        }
    }, [inputValues?.[reportsData.dropdownId], companies])
    
    const getReportsData = (data, callback) => {
        setMasked(true);
        const params = {
            Search: data.searchTerm,
            PageIndex: data.currentPage,
            PageSize: data.rowCount,
            OrderBy: data.orderBy,
            Desc: data?.desc,
        }
        const dropdowns = tableDropdowns?.[tableId];
        if (dropdowns) {
            Object.keys(dropdowns).forEach((key) => {
                params[key] = dropdowns[key].value;
            });
        }
        if (data.periodStart) {
            params.PeriodStart = data.periodStart;
            params.PeriodEnd = data.periodEnd;
        }
        data.callback = callback;
        callAPIGet('report-CompanyPerformance', companyPerformanceCallback, params, data);
    }

    const companyPerformanceCallback = (data, props) => {
        setMasked(false);
        if (data.data && props.callback) {
            props.records = data.data;
            props.pageCount = data.pageCount ? data.pageCount : '';
            props.callback(props);
        }
    }

    const companiesCallback = (data) =>{
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName',
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        updateTable('selectedRows', tableId, null);
        setTimeout(() => {
            updateTable('dates', tableId, range);
        }, 0)
    }

    return (<TablePaged
        id={tableId}
        layout={layout}
        defaultDates={defaultDates}
        apiId='report-GamePerformance'
        apiCall={getReportsData}
        searchBarContent={<>
        <Dropdown
            id={companyId}
            classes='quarter'
            label={t.companyName}
            data={companies}
            masked={masked?.[0]}
            nullable={t.all}
            hideErrors={true}
            callback={onCompanySelect}
        />
        <DateRangePicker
            id='companyReport-dateRange'
            classes='third'
            defaultVal={defaultDates}
            label={t.dateRange}
            stateId={tableId}
            disabled={masked?.[0]}
            callback={onDateRangePick}/>
        </>}
    />)
}

export default CompanyPerformanceReports;
