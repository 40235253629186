import './forgotPassword.scss';
import { useEffect, useRef, useState } from 'react';
import {text as t} from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Link from 'components/Link/Link';
import logo from 'shared/img/primero-logo.png';
import bg from 'shared/img/login-bg.jpg';

const getInitialAttempts = () => {
    const attempts = parseInt(localStorage.getItem('attempts'), 10)  || 0;
    const lastAttemptTime = Number(localStorage.getItem('lastAttemptTime')) || 0;
    const currentTime = Math.floor(Date.now() / 1000);
    if (lastAttemptTime && (currentTime - lastAttemptTime) <  3600 * 12) {
        return attempts;
    } else {
        localStorage.setItem('attempts', '0');
        return 0;
    }
};

const ForgotPassword = () => {
    const {callForgotPassword} = useAuth();
    const {inputValues, changeInputErrors, validateForm} = useInputs();
    const runOnce = useRef(false);
    const attempts = useRef(getInitialAttempts());
    const skippedMount = useRef(false);
    const emailId = 'portal-forgot-password-email';
    const [time, setTime] = useState(60);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        const currentTime = Math.floor(Date.now() / 1000);
        const lastAttemptTime = Number(localStorage.getItem('lastAttemptTime')) || 0;
        const currentTimeout = (attempts.current !== 5 ? attempts.current * 60 : 3600 * 12)
        const remainingTime = currentTime - lastAttemptTime < currentTimeout ? currentTimeout - (currentTime - lastAttemptTime) : 0
        if (lastAttemptTime && remainingTime) {
            resetTimer(Number(remainingTime));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('attempts', attempts.current.toString());
    }, [attempts.current]);

    useEffect(() => {
        let timer;
        if (isRunning && time > 0) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1);

            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [isRunning, time]);

    const resetTimer = (value) => {
        setIsRunning(true);
        setTime(value);
        setTimeout(() => {

            setIsRunning(false);
        }, value * 1000);
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };


    useEffect(() => {
        if (runOnce.current) {
            return;
        }
        runOnce.current = true;
        const appElement = document.getElementById('App');
        Object.assign(appElement.style, {
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        });

        return () => {
            if (skippedMount.current) {
                Object.assign(appElement.style, {
                    backgroundImage: '',
                    backgroundSize: '',
                    backgroundRepeat: '',
                    backgroundPosition: '',
                });
            } else {
                skippedMount.current = true;
            }
        };
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress);
        return () => {
            document.removeEventListener('keydown', onKeyPress);
        };
    }, []);

    const onKeyPress = ( event ) => {
        if (event.key === 'Enter') {
            const submitButton = document.getElementById('forgot-password-submit-button');
            submitButton.click();
        }
    };

    const onSubmit = ( event, setLoading ) => {
        const forgotPasswordEmail = inputValues[emailId];
        const validators = [
            {id: emailId, checks: ['required']},
        ];
        const errorsList = validateForm(validators);

        if (errorsList) {
            changeInputErrors(errorsList);
        } else {
            setLoading(true);

            changeInputErrors({});
            const params = {
                email: forgotPasswordEmail,
                baseUrl: `${window.location.origin.toString()}/reset-password`,
                organizationId: 1,
            };
            const props = {
                email: forgotPasswordEmail,
                setLoading: setLoading,
            };
            callForgotPassword(params, onSuccess, onError, props);
        }
    };

    const onSuccess = () => {
        localStorage.setItem('lastAttemptTime', Math.floor(Date.now() / 1000).toString());
        attempts.current = attempts.current + 1;
        let timeout = attempts.current !== 5 ? attempts.current * 60 : 3600 * 12;
        resetTimer(timeout);
    };

    const onError = ( err ) => {
        const response = err.response;
        const status = response.status;
        let message;
        switch (status) {
            case 403:
                message = t.emailInvalid;
                break;
            case 404:
                message = t.errorNoEndpoint;
                break;
            default:
                return t.errGeneral;
        }
        changeInputErrors({[emailId]: message});
    };

    return (
        <div className='forgot-password'>
            <div className='forgot-password-form-container grid'>
                <div className='forgot-password-logo full centered'>
                    <img className='forgot-password-logo-image' src={logo} alt='logo'/>
                </div>
                {!isRunning && <div className='forgot-notice-container full centered'>
                    {t.forgotPasswordInstructions}
                </div>}
                {isRunning && <div className='forgot-message-container full centered'>
                    <span className='resend-span'>{t.emailIsAlreadySent}</span>
                </div>}
                {isRunning && <div className='forgot-timer-container full centered'>
                    <span className='resend-span'>Please wait {formatTime(time)} to retry</span>
                </div>}
                {!isRunning && <form className='forgot-form full grid'>
                    <Input
                        classes='forgot-password-input half centered'
                        id={emailId}
                        startFocused={true}
                    />
                </form>}
                <Button
                    id='forgot-password-submit-button'
                    classes='forgot-password-submit blue third centered'
                    type='submit'
                    disabled={isRunning}
                    onClick={( event, setLoading ) => {onSubmit(event, setLoading)}}
                >{t.submit}</Button>
                <div className='forgot-cancel-link-container full centered'>
                    <Link link={'/login'}>{t.cancel}</Link>
                </div>
            </div>
        </div>
    );

};

export default ForgotPassword;
