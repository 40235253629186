import './sources.scss';
import { useEffect, useRef, useState } from 'react';
import { sourcesLayout as layout } from './SourcesLayout';
import useUtils from 'hooks/useUtils';
import useProfile from 'hooks/Profile/useProfile';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const Sources = () => {
    const { sources, setSources,
            refreshPage, setRefreshPage } = useProfile();
    const { callAPI } = useUtils();
    const [ tableLoading, setTableLoading ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('sources', sourcesCallback, { updateSourceId: null });
    }, [])

    useEffect(() => {
        if (refreshPage) {
            setTableLoading(true);
            callAPI('sources', sourcesCallback, { updateSourceId: null });
        }
    }, [refreshPage])

    const sourcesCallback = (data) =>{
        setTableLoading(false);
        setSources(data?.tableData || []);
        setRefreshPage(false);
    }

    return (
        <Page page={layout.page}>
            <Table 
                data={sources}
                layout={layout} 
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default Sources;