import { useEffect, useRef, useState } from 'react';
import { addCellClasses, buildSpacer, 
         formatContent, runFilters } from 'app-components/Table/tableUtils';
import { deepCopy, hasDecimals,
         isValid, objectEmpty } from 'components/shared/componentUtils';
import {text as t} from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useProfile from 'hooks/Profile/useProfile';
import RowCountPicker from '../RowCountPicker/RowCountPicker';
import TableSearchBar from '../TableSearchBar/TableSearchBar';
import TableHeaders from '../TableHeaders/TableHeaders';
import TableBody from '../TableBody/TableBody';
import TableNav from '../TableNav/TableNav';

const Table = ({data, layout, 
                context, loading, 
                rowClickCallback, editable, 
                searchBarContent }) => {
    let id = layout?.id;
    const { getCountry } = useAuth();
    const { tableStates, setTableStates } = useProfile();
    const [ currentLayout, setCurrentLayout ] = useState(tableStates?.[id]?.layout ? tableStates[id].layout : layout);
    const [ displayData, setDisplayData ] = useState();
    const currentFilters = useRef();
    const country = useRef(getCountry());
    const classes = layout.classes;

    useEffect(()=>{
        if (!isValid(layout)) { return };
        layout.countryCode = country.current;
        setCurrentLayout(layout)
        tableStates && initializeStates();
    }, [layout])

    useEffect(() => {
        if (!isValid(data)) { return };
        tableStates && initializeStates();
    }, [data]);

    useEffect(()=> {
        if (!data) { return };
        if (JSON.stringify(tableStates[id]?.filters) === currentFilters.current) { return }
        tableStates && initializeStates();
    }, [currentLayout])

    useEffect(() => {
        if (!data) { return };
        if (JSON.stringify(tableStates[id]?.filters) === currentFilters.current) { return }
        configureTableLayout();
        setDisplayData(runFilters(id, data, currentLayout, tableStates));
    }, [tableStates[id]]);

    useEffect(() => {
        if (!currentLayout || !tableStates?.[id]) { return };
        const pages = Math.ceil(displayData?.length / tableStates?.[id].rowCount);
        if (isNaN(pages)) {return};
        const newStates = deepCopy(tableStates);
        newStates[id].pageCount = pages;
        currentFilters.current = JSON.stringify(newStates[id].filters);
        if (currentLayout?.subtotals) {
            const formats = currentLayout.formats;
            const fields = currentLayout.subtotals.fields;
            const columns = Object.keys(currentLayout.tableHeaders);
            let totals = {[columns[0]]: t.results}
            const length = columns.length;
            for (let i=1; i<length; i++) {
                const key = columns[i];
                const format = formats[key];
                let total = getTotal(fields[key], key);
                const totalVal = (format !== 'text') ? parseFloat(total) : total ? total : t.nullVal;
                totalVal ? totals[key] = totalVal : totals[key] = total;
            }
            newStates[id].totals = totals;
        }
        setTableStates(newStates);
    }, [displayData]);

    const initializeStates = () => {
        const newStates = deepCopy(tableStates);
        let state = newStates[id];
        if (!state) { state = {} }
        if (!state.rowCount) {
            const rows = currentLayout.rowCount ? currentLayout.rowCount : getRowsPerPage();
            state.rowCount = rows ? rows : t.defaultRowCount;
        };
        if (!state.range) { state.range = [1, 2] };
        if (!state.filters) { state.filters = {inputValue:{}} };
        if (!state.properties) { state.properties = Object.keys(currentLayout.tableHeaders) };
        if (!state.currentPage) { state.currentPage = [1] }
        newStates[id] = state;
        setTableStates(newStates);
    }

    const configureTableLayout = () => {
        const body = document.getElementById(id + '-table-body');
        if (!body || !body.childNodes[0]?.classList.contains('empty')) { return };
        const state = tableStates[id];
        const inputValue = state.filters.inputValue;
        if (!objectEmpty(inputValue)) {
            document.getElementById(inputValue.name).value = inputValue.value;
        }

        const rowCount = state.rowCount;
        if (rowCount) {
            const picker = document.getElementById(id + '-count-picker');
            if (picker) {
                const options = picker.options;
                const length = options.length;
                for (let i = 0; i < length; i++) {
                    const option = options[i];
                    if (String(option.value) === String(rowCount)) {
                        option.selected = true;
                    }
                }
            }
        }

        const orderSort = state.filters.orderSort;
        if (orderSort) {
            const orderArrow = document.getElementById(id).getElementsByClassName(`${id}-order-${orderSort.prop}`)[0];
            const classes = orderArrow.classList;
            classes.remove('bi-arrow-down-up');
            classes.add(orderSort.value);
        }
    }

    const getRowsPerPage = () => {
        const max = tableStates?.[id]?.rowCount ? tableStates[id].rowCount: currentLayout.rowCount ? currentLayout.rowCount : 10;
        const items = data?.length;
        return max > items ? items : max;
    }

    const buildTotalRow = () => {
        if (!displayData) { return };
        const columns = Object.keys(currentLayout.tableHeaders);
        const sizes = currentLayout.sizes;
        let first = 0;
        return <div className={`table-row total sticky`}>
            {columns.map((cell, i) => {
                const type = currentLayout.subtotals?.fields?.[cell];
                const classes = addCellClasses(cell, currentLayout, 'filler', null);
                if (classes.includes('hidden')) {
                    first ++;
                    return null;
                } else if (i === first) {
                    return <div key={i} className={`table-cell sticky-column ${classes}`}>
                        <div className='table-cell-content'>{t.results}</div>
                    </div>
                } else if (type) {
                    const content = formatContent(getTotal(type, cell), cell, currentLayout);                    
                    return <div key={i} className={`table-cell ${addCellClasses(cell, currentLayout, content ? content : 'filler', null)}`}>
                        <div className={`table-cell-content ${type ? 'total ' + type : ''}`}>
                            {content}
                        </div>
                    </div>
                } else {
                    return buildSpacer(sizes[cell], null, i);
                }
            })}
        </div>
    }

    const getTotal = (type, column) => {
        const obj = currentLayout?.customCalculation && currentLayout.customCalculation[column];
        if (obj) {
            return getCustomTotal(obj);
        } else  {
            switch (type) {
                case 'average':
                    return getTotalAverage(column);
                case 'average-to-weight':
                    return getTotalAverageToWeight(column);
                case 'average-weighted':
                    return getTotalAverageWeighted(column);
                case 'max':
                    return getTotalMax(column);
                case 'na':
                    return t.na
                default:
                    return getTotalSum(column);
            }
        }
    }

    const getCustomTotal = (obj) => {
        const first = obj.first;
        const second = obj.second;
        const firstVal = getTotal(first.type, first.column);
        const secondVal = getTotal(second.type, second.column);
        let value;
        switch (obj.calc) {
            case 'divide':
                const result = firstVal / secondVal;
                value =  isValid(result) ? result : 0;
                break;
            case 'multiply':
                value =  firstVal * secondVal;
                break;
            default:
                value = firstVal / secondVal;
        }
        switch (obj.format) {
            case 'decimal':
                value = value * 100;
                break;
            case 'one-in-x':
                value = '1 in ' + value.toFixed(2);
                break;
            case 'frequency-percentage':
                value = !value ? 0 : 1 / value * 100;
                break;
            default: 
        }
        return value;
    }

    const getTotalAverage = (column) => {
        const length = displayData.length;
        let total = 0;
        for (let i=0; i<length; i++) {
            const val = parseFloat(displayData[i][column]);
            total += val ? val : 0;
        }
        if (total) {
            let average = total/length;
            if (!hasDecimals(average)) {
                return parseInt(average)
            } else {
                return +(total/length).toFixed(2);
            }
        } else {
            return 0;
        }
    }

    const getTotalAverageToWeight = (column) => {
        const data = layout.subtotals.averageWeights[column];
        const valProp = data.value;
        const valWeightProp = data.valueWeight;
        const weightProp = data.weight;
        const length = displayData.length;
        let totalVal = 0;
        let totalWeight = 0;
        for (let i=0; i<length; i++) {
            const value = parseFloat(displayData[i][valProp]);
            const valWeight = displayData[i][valWeightProp];
            const weight = displayData[i][weightProp];
            const trueVal = value * valWeight;
            totalWeight += weight ? weight : 0;
            totalVal += trueVal ? trueVal : 0;
        }
        if (totalVal) {
            const average = totalVal/totalWeight;
            if (!hasDecimals(average)) {
                return parseInt(average)
            } else {
                return +(average).toFixed(2);
            }
        } else {
            return 0;
        }
    }

    const getTotalAverageWeighted = (column) => {
        const data = layout.subtotals.averageWeights[column];
        const valProp = data.value;
        const weightProp = data.weight;
        const length = displayData.length;

        let totalVal = 0;
        let totalWeight = 0;
        for (let i=0; i<length; i++) {
            const baseVal = parseFloat(displayData[i][valProp]);
            const baseWeight = parseFloat(displayData[i][weightProp]);
            totalWeight += baseWeight ? baseWeight : 0;
            totalVal += baseVal ? baseVal : 0;
        }
        if (totalVal && totalWeight) {
            let average = totalVal/totalWeight;
            if (layout.formats[column] === 'percentage') {average = average * 100};
            if (!hasDecimals(average)) {
                return parseInt(average)
            } else {
                return +(average).toFixed(2);
            }
        } else {
            return 0;
        }
    }

    const getTotalMax = (column) => {
        const length = displayData.length;
        let max = 0;
        for (let i=0; i<length; i++) {
            const value = displayData[i][column];
            if (value > max) {
                max = value
            };
        }
        if (!hasDecimals(max)) {
            return parseInt(max);
        } else {
            return +max.toFixed(2);
        }
    }

    const getTotalSum = (column) => {
        const length = displayData.length;
        let sum = 0;
        for (let i=0; i<length; i++) {
            sum += parseFloat(displayData[i][column]);
        }
        if (!hasDecimals(sum)) {
            return parseInt(sum)
        } else {
            return +sum.toFixed(2);
        }
    };

    return (
        <div id={id} className={`table ${classes ? classes : ''} `} >
            <TableSearchBar
                layout={layout}
                currentLayout={currentLayout}
                setCurrentLayout={setCurrentLayout}
                searchBarContent={searchBarContent}
                editable={editable}
            />
            <div id={id + '-section'} className={`table-section ${currentLayout.navStyle === 'arrow' ? 'arrow' : ''}`}>
                <div id={id + '-container'} className={`table-container ${currentLayout.sideScrollable ? 'side-scrollable' : ''}`}>
                    <TableHeaders
                        layout={currentLayout}
                        data={data}
                        loading={loading}
                    />
                    <TableBody
                        layout={currentLayout}
                        displayData={displayData}
                        context={context}
                        loading={loading}
                        cleanData={data}
                        country={country.current}
                        rowClickCallback={rowClickCallback}
                    />
                    {currentLayout?.subtotals && buildTotalRow()}
                </div>
                {(!currentLayout.subtotals && !currentLayout.hideNav) && <div className={`table-nav-bar-container ${currentLayout.hideNav ? 'hide' : ''}${currentLayout.sideScrollable ? 'side-scrollable' : ''}  ${classes && classes.includes('static') ? 'static' : ''}`}>
                    <div className='table-nav-bar'> 
                        {!currentLayout.hideRowCountPicker && <RowCountPicker
                            layout={currentLayout}
                            data={displayData}
                        />}
                        <TableNav
                            layout={currentLayout}
                            data={displayData}
                        />
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Table;
