import './tableHeaders.scss';
import { useEffect, useState } from 'react';
import { addCellClasses, getMax, updateFilters } from 'app-components/Table/tableUtils';
import { deepCopy } from 'components/shared/componentUtils';
import useProfile from 'hooks/Profile/useProfile';
import Icon from 'components/Icon/Icon';

const TableHeaders = ({ layout, data, loading }) => {
    const { tableStates, setTableStates, refreshPage } = useProfile();
    const [ currentArrow, setCurrentArrow ] = useState('');
    const id = layout.id;
    const headers = layout.tableHeaders;    

    useEffect(() => {
        refreshPage && setCurrentArrow('');
    }, [refreshPage])
    
    const buildHeaderRow = () => {
        const headerList = Object.keys(headers);
        return headerList.map((prop, i) => {
            if (Array.isArray(headers[prop])) {
                if (!data) {return}
                const max = getMax(prop, data);
                const label = headers[prop][0];
                const result = []
                for (let j=0; j<max; j++) {
                    const fullLabel = label + ' ' + (j+1);
                    result.push(buildHeader(fullLabel, prop, j));
                }
                return result;
            } else if (typeof prop === 'object') {
                const keyProp = prop.prop;
                const key = prop.key;
                return buildHeader(headers[key][keyProp], keyProp, i, key);
            }
            else {
                return buildHeader(headers[prop], prop, i);
            }
        })
    }

    const buildHeader = (value, prop, i, key) => {
        const hide = layout.hideSortArrows?.[prop];
        const checked = tableStates?.[id]?.checkAll ? true : false;
        return <div key={i} className={`table-header-cell ${addCellClasses(prop, layout, value, key)} ${prop === 'checkbox' ? 'check-all' : ''}`}>
            {prop === 'checkbox' ?<label className='table-checkbox'>
                <input
                    id={id + 'table-check-all'}
                    className='table-multi-check-all'
                    type='checkbox'
                    checked={checked}
                    onChange={(event)=>{onMultiCheckAll(event.target)}}
                />
                <span className='checkmark'></span>
            </label>
            : <div className={`table-header-cell-contents ${id}-order-${prop}`} data-prop={prop}>
                <div className={`table-header-cell-text ${hide ? 'no-click' : ''}`} onClick={()=>{onSortArrowClick(prop)}}>{value}</div>
                {currentArrow?.prop === prop && currentArrow?.value && <Icon icon={currentArrow.value} classes={`table-header-sort-arrow ${hide ? 'hide' : ''}`}/>}
            </div>}
        </div>
    }

    const onMultiCheckAll = (target) => {
        const checked = target.checked;
        const list = [];
        const newStates = deepCopy(tableStates);
        if (checked) {
            const checkboxes = document.getElementsByClassName('table-multi-check');
            const length = checkboxes.length;
            for (let i=0; i<length; i++) {
                list.push(checkboxes[i].dataset.id);
            }
        }
        newStates[id].checkAll = target.checked;
        newStates[id].multiCheck = list;
        setTableStates(newStates);
    }

    const onSortArrowClick = (prop) =>{
        if(layout.hideSortArrows?.[prop]) {return};
        const newStates = deepCopy(tableStates);
        const nextArrow = prop !== currentArrow?.prop ? 'arrow-down' : nextArrowState(currentArrow);
        const orderSort = {
            prop: prop,
            value: nextArrow
        }
        setCurrentArrow(orderSort);
        newStates[id].filters.orderSort = orderSort;
        updateFilters(newStates, setTableStates);
    }

    const nextArrowState = () => {
        switch (currentArrow?.value) {
            case 'arrow-up':
                return '';
            case 'arrow-down':
                return 'arrow-up';
            default:
                return 'arrow-down'
        }
    }

    return (
        <div className={`table-header ${layout.hideSearch ? 'hide' : ''} ${layout.classes ? layout.classes : ''} `}>
            <div className={`table-header-loading-mask ${!loading ? 'hide' : ''}`}/>
            {buildHeaderRow()}
        </div>
    )
}

export default TableHeaders;
