import { cloneDeep } from 'lodash';
import { componentText as t, keydownSets } from './componentData';

export const isThen = (val, prop) => {
    return val ? (prop !== undefined ? prop : val) : '';
}

export const ifElse = (val, prop) => {
    return isValid(val) ? val : prop !== undefined ? prop : '';
}

export const controlForPercentage = (event, key, val) => {
    val = val.toString();
    if (isNaN(key) && key !== '.') {
        event.preventDefault();
    } else if (val) {
        if (val.length >= 5) {
            event.preventDefault();
        } else if (val.includes('.') && key === '.') {
            event.preventDefault();
        } else if (val >= 100 && key === '.') {
            event.preventDefault();
        } 
    } 
}

export const deepCopy = (object) => {
    return object ? cloneDeep(JSON.parse(JSON.stringify(object))) : {};
}

export const extractNums = (string) => {
    if (typeof string == 'number') {return string.toString()}
    const matches = string.match(/\d+/g);
    return matches ? matches.join('') : '';
}

export const getCurrentDateTime = (date) => {
    let now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();
    let milliseconds = now.getMilliseconds();
    return new Date(date.setHours(hours, minutes, seconds, milliseconds));
}

export const getDefaultDateRange = (tableId) => {
    const currentParams = JSON.parse(new URLSearchParams(location.search).get(tableId));
    const lastURL = sessionStorage.getItem('lastURL');
    const lastParams = lastURL ? JSON.parse(lastURL) : null;
    const useLast = checkPersistList(tableId, lastParams);
    const dates = currentParams ? currentParams.dates : useLast ? lastParams?.props?.dates : null;
    if (dates) {
        return [new Date(dates[0]), new Date(dates[1])];
    } else {
        return [
            setToStartOfDay(getWeekPriorDate(new Date())), 
            setToEndOfDay(new Date())
        ];
    }
}

const checkPersistList = (tableId, params) => {
    if (!tableId) {return false};
    const parts = tableId.split('-');
    const type = parts[0];
    const id = parts[parts.length-1];

    if (!params) {return false}
    const lastId = params.id;
    if (lastId &&
        lastId.includes(type) && ( 
        lastId.includes(id) || 
        lastId.includes('performance') ||
        id === 'performance')) {
        return true;
    } else {
        return false;
    }
}


export const getAPIDates = (range) => {
    return {
        periodStart: convertToUTC(range[0]),
        periodEnd: convertToUTC(range[1]),
    };
};

const convertToUTC = (date) => {
    return new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    ));
};


export const getWeekPriorDate = (date) => {
    let newDate = new Date();
    return new Date(newDate.setDate(date.getDate() - 6));
}

export const getFormId = (section, line, i) => {
    const parts = [section, i, line];
    const id = parts.filter(part => part !== undefined && part !== null).join('-');
    return id;
}

export const getFormProps = (data, objectInfo) => {
    const baseKeys = Object.keys(data);
    let keys = [];
    baseKeys.forEach((section)=>{
        const item = data[section];
        if (isValid(item) &&  typeof item === 'object') {
            const sectionKeys = Object.keys(item);
            sectionKeys.forEach((line)=>{
                keys.push({
                    id: getFormId(section, line),
                    section: section,
                    line: line,
                })
            })
        } else {
            keys.push({
                id: section,
            });
        }
    });
    return keys;
}

export const getLabelFromOptions = (defaultVal, options, nullable, valueProp, labelProp, appendProp) => {
    if (!isValid(defaultVal) || (!defaultVal && nullable)) { 
        return nullable ? nullable : null
    };
    defaultVal = defaultVal.toString();
    const length = options?.length ? options.length : 0;
    for (let i = 0; i < length; i++) {
        const item = options[i];
        const id = isValid(item[valueProp]) ? item[valueProp].toString() : isValid(item.id) ? item.id.toString() : item.value;
        const val = isValid(item.value) ? item.value.toString() : isValid(item.id) ? item.id.toString() : item.value;
        if ((defaultVal === id || defaultVal === val)) {
            return getObjectLabel(item, labelProp, appendProp, valueProp);
        } else if (nullable && !defaultVal && !item.value) {
            return getObjectLabel(item, labelProp, appendProp, valueProp);
        }
    }
    return '';
}

export const getInputType = (type) => {
    switch (type) {
        case 'currency': 
        case 'currency-decimal':
        case 'max100':
        case 'percentage':
        case 'phone':
        case 'zip':
            return 'number'
        default: 
            return 'text'
    }
}

export const getMax = (prop, data) => {
    const length = data.length;
    let count = 0;
    for (let i = 0; i < length; i++) {
        const itemCount = data[i][prop].length;
        if (itemCount > count) { count = itemCount };
    }
    return count;
}

export const getMaxLength = (type, max) => {
    switch (type) {
        case 'currency': 
        case 'currency-decimal': 
            return 15; // JS ints have a 16 char limit before rounding becomes an issue
        case 'grc-pin':
            return 12
        case 'phone':
            return 10;
        case 'zip':
            return 5;
        default: 
            return max ? max : 50;
    }
}

export const getNested = (string, data) => {
    const properties = string.split('.');
    const length = properties.length;
    let result = data;
    for (var i = 0; i < length; i++) {
        var prop = properties[i];
        result = result[prop];
    }
    return result;
}

export const getObjectLabel = (item, labelProp, appendProp, valueProp) => {
    let label = item[labelProp] ? item[labelProp] : item.name ? item.name : item.label ? item.label : item.description ? item.description : item.id ? item.id : t.nullVal;
    const val = item?.[valueProp] ? item[valueProp] : item.id;
    if (appendProp && val && item[appendProp]) {
        label = label + ' (' + item[appendProp] + ')';
    } else {
        label = isValid(label) ? label.toString() : t.nullVal
    }
    return label;
}

export const getReportURLId = (page, type, id) => {
    return `${page}${type.charAt(0).toUpperCase() + type.slice(1)}-${id}`
}


export const getValue = (data, line, section, index, type) => {
    if (!data) return '';
    let path = [];
    if (section !== undefined) path.push(section);
    if (typeof index === 'number' && index >= 0) path.push(index);
    path.push(line);

    let val = path.reduce((acc, curr) => acc?.[curr], data);
    if (isObject(val) && 'name' in val) {
        return val.name;
    }
    if (!isValid(val)) {
        val = ''
    } else if (isCustomFormat(type) || type === 'number') {
        if (type === 'phone' && val.startsWith('+1')) {
            val = val.slice(2);
        }
        val = val.replace(/\D+/g, '');
    }
    return (val !== undefined && val !== null) ? val : '';
}

const isObject = (value) => {
    if (typeof value === 'object' && isValid(value)) {
        return true;
    } else {
        return false;
    }
}

export const hasDecimals = (num) => {
    return num !== Math.trunc(num);
}

export const hasLower = (string) => {
    return string.split('').some(char => char === char.toLowerCase() && char !== char.toUpperCase());
};

export const hasNumber = (string) => {
    return /\d/.test(string);
};

export const hasUpper = (string) => {
    return string.split('').some(char => char === char.toUpperCase() && char !== char.toLowerCase());
};

export const hasValidSpecial = (string) => {
    return /[!?@#$%&*+=._-]/.test(string);
}

export const hasInvalidSpecial = (string) => {
    return /[\/\\^(){}\[\],'"`]/.test(string);
}

export const isCustomFormat = (type) => {
    if (type === 'currency' ||
        type === 'currency-decimal' ||
        type === 'grc-pin' ||
        type === 'phone') 
    {
        return true;
    } else {
        return false;
    }
}

export const isNum = (char) => {
    return /^\d$/.test(char);
}

export const isValid = (value, nullable) => {
    return !(value === undefined || (value === null && !nullable) || Number.isNaN(value));
}

export const keyType = (val, type) => {
    const array = keydownSets[type];
    return array.includes(val) ? true : false;
}

export const objectEmpty = (obj) => {
    if (!obj || !Object.keys(obj)) {
        return true;
    }
    return (Object.keys(obj).length === 0);
}

export const removeFromArray = (val, array) => {
    const  index = array.indexOf(val);
    array.splice(index, 1);
}

export const setToStartOfDay = (date) => {
    let newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
};

export const setToEndOfDay = (date) => {
    let newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
};

export const sortList = (array, prop, desc) => {
    if (!array || array.length === 0) {return []};
    const newArray = [...array];

    const getListVal = (item, prop) => {
        return Array.isArray(prop) ? prop.reduce((acc, key) => (acc ? acc[key] : undefined), item) : item[prop];
    };

    const typeItem = (() => {
        for (const item of newArray) {
            const value = getListVal(item, prop);
            if (value !== undefined && value !== null && value !== '') {
                return value;
            }
        }
        return undefined;
    })();
    let type = typeof typeItem;
    if (type === 'string' && !isNaN(typeItem)) {
        type = 'number';
    }

    const compareString = (a,b) => {
        const aValue = a[prop]?.toString().trimStart() || null;
        const bValue = b[prop]?.toString().trimStart() || null;
        if (aValue === null || bValue === null) {
            return handleNull(aValue, bValue);
        }
        return bValue.localeCompare(aValue); 
    };

    const compareValue = (a, b) => {
        const aValue = a[prop];
        const bValue = b[prop];

        const parsedA = isValid(aValue) ? parseInt(aValue) : null;
        const parsedB = isValid(bValue) ? parseInt(bValue) : null;
    
        if (parsedA === null || parsedB === null) {
            return handleNull(parsedA, parsedB);
        }
        return parsedB - parsedA;
    };

    const compareBoolean = (a, b) => {
        const aValue = a[prop];
        const bValue = b[prop];
    
        const parsedA = isValid(aValue) ? (aValue ? 1 : 0) : null;
        const parsedB = isValid(bValue) ? (bValue ? 1 : 0) : null;
    
        if (parsedA === null || parsedB === null) {
            return handleNull(parsedA, parsedB);
        }
        return parsedB - parsedA;
    };

    const handleNull = (a, b) => {
        if (a === null && b !== null) return desc ? 1 : -1; 
        if (b === null && a !== null) return desc ? -1 : 1; 
        return 0; 
    }
    switch (type) {
        case 'string':
            return desc
                ? newArray.sort((a, b) => compareString(a, b))
                : newArray.sort((a, b) => compareString(b, a))
        case 'number':
            return desc
                ? newArray.sort((a, b) => compareValue(a, b)) 
                : newArray.sort((a, b) => compareValue(b, a))
        case 'boolean':
            return desc
                ? newArray.sort((a, b) => compareBoolean(a, b)) 
                : newArray.sort((a, b) => compareBoolean(b, a))
        default:
            return newArray;
    }
};

export const sortObjectArrayAsc = (data) => {
    const length = data?.length ? data.length : 0;
    switch (length) {
        case 0:
            return [];
        case 1: 
            return data;
        default:
            return data.sort(( a, b ) => a.key - b.key);
    }
}

export const toCurrency = (val, country) => {
    if (isNaN(val)) {return t.na};
    if (!val) {val = 0};
    return getCurrencyString(val, country);
};

export const toCurrencyDecimal = (val, country) => {
    if (isNaN(val)) {return t.na};
    if (!val) {val = 0};
    val /= 100;
    return getCurrencyString(val, country);
}

export const toCurrencyWhole = (val, country) => {
    if (isNaN(val)) {return t.na};
    if (!val) {val = 0};
    val = Math.round(val);
    return getCurrencyString(val, country);
};

const getCurrencyString = (val, country) => {
    const sign = Math.sign(val);
    const absValue = Math.abs(val);
    const formattedValue = absValue.toLocaleString('en-US', getFormat(country));
    const signedValue = sign === -1 ? '-' + formattedValue : formattedValue;
    return signedValue.toString();
}

const getFormat = (country) => {
    switch (country) {
        case 'UG':
            return {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        default:
            return {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
    }
}

export const toGRCPin = (val)=> {
    if (!val) { return '' }
    let maskedString = val.slice(0, -1).replace(/\d/g, '*') + val.slice(-1);
    return maskedString.match(/.{1,2}/g).join(' ');
}

export const toNum = (val) => {
    if (!val)  { return 0};
    return val.toLocaleString('en-US');
}

export const toPhone = (incoming) => {
    if (!incoming) { return ''};
    const normalized = incoming.startsWith('+1') ? incoming.substring(2) : incoming;
    const input = normalized.replace(/\D/g, '').substring(0, 10);
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    const length = input.length;
    let string;
    if (length > 6 ) {
        string = `(${areaCode}) ${middle} - ${last}`;
    } else if (length > 3) {
        string = `(${areaCode}) ${middle}`
    } else if (length > 0) {
        string = `(${areaCode}`
    } else {
        string = ''
    };
    return string;
};

export const toPin = (str) => {
    if (!str) {return ''}
    const pairs = str.match(/.{1,2}/g);
    return pairs.join('-');
}

export const toSimpleDate = (str) => {
    const date = new Date(str);
    if (isNaN(date.getTime())) {return str};
    const options = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    };
    return date.toLocaleString('en-US', options);
}

export const toStandardDate = (str) => {
    const date = new Date(str);
    if (isNaN(date.getTime())) {return str};
    const options = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };
    return date.toLocaleString('en-US', options);
}

export const toZip = (val) => {
    return val.length >= 6 ? val.substring(0, 5) + ' - ' + val.substring(5) : val;
}

export const newCopy = (id, newData, obj) => {
    const newObj = deepCopy(obj);
    newObj[id] = newData;
    return newObj;
}

export const updatePageState = (data, state) => {
    const newState = deepCopy(state);
    const { section, value, line, id, index } = data;  
    if (section) {
        if (index !== undefined && index !== null) {
        if (!newState[section]) newState[section] = {};
        if (!newState[section][index]) newState[section][index] = {};  
            newState[section][index][line] = value;
        } else {
        if (!newState[section]) newState[section] = {};  
            newState[section][line] = value;
        }
    } else if (line) {
        newState[line] = value;
    } else {
        newState[id] = value;
    }
    return newState;
};

export const updateURL = (id, type, data) => {
    const location = window.location;
    const history = window.history;
    const searchParams = new URLSearchParams(location.search);
    const urlParams = searchParams.get(id) ? JSON.parse(searchParams.get(id)) : {};
    // Update the type within the id
    if (data) {
        urlParams[type] = data;
    } else {
        delete urlParams[type];
    }

    // Update or remove the id in the URL search params
    if (Object.keys(urlParams).length > 0) {
        searchParams.set(id, JSON.stringify(urlParams));
    } else {
        searchParams.delete(id);
    }

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.replaceState({}, '', newUrl);

    if (searchParams.size === 0 && !data) {
        const newUrl = location.pathname;
        history.replaceState({}, '', newUrl);
    }
}

export const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9!#$%&'+/=?^_`{|}~][a-zA-Z0-9!#$%&'+/=?^_`{|}~.-]*[a-zA-Z0-9!#$%&'+/=?^_`{|}~]@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    return regex.test(email);
}
