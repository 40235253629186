import './reports.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { reportsData } from 'shared/data';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
import CompanyPerformanceReports from 'pages/Reports/CompanyPerformanceReports/CompanyPerformanceReports';
import GamePerformanceReports from 'pages/Reports/GamePerformanceReports/GamePerformanceReports';
import GameroomPerformanceReports from 'pages/Reports/GameroomPerformanceReports/GameroomPerformanceReports';
import LocationPerformanceReports from 'pages/Reports/LocationPerformanceReports/LocationPerformanceReports';
import KioskPerformanceReport from '../KioskPerformanceReport/KioskPerformanceReport';
const performanceReports = reportsData.subtypes.performance;

const reportPages = {
    'company-performance': <CompanyPerformanceReports/>,
    'location-performance': <LocationPerformanceReports/>,
    'game-performance': <GamePerformanceReports/>,
    'gameroom-performance': <GameroomPerformanceReports/>,
    'kiosk-performance': <KioskPerformanceReport/>
}

const Reports = () => {
    const { type } = useParams();
    const { navTo } = useComponents();
    const { updateDropdown } = useInputs();
    const [ currentReport, setCurrentReport ] = useState(type || performanceReports[0].value);

    useEffect(() => {
        updateDropdown(reportsData.dropdownId, type, getReportLabel(type));
        setCurrentReport(type);
    }, [type])

    useEffect(() => {
        type !== currentReport && navTo(`/reports/${currentReport}`);
    }, [currentReport]);

    const onReportSelect = ( data ) => {
        setCurrentReport(data.value);
    }

    const getReportLabel = (type) => {
        for (let i=0; i<performanceReports.length; i++) {
            const page = performanceReports[i];
            if (page.value === type) {
                return page.label;
            }
        }
    }

    return (
        <Page page={t.reports}>
            <Card hideLabel={true} classes='grid margin-bottom'>
                <Dropdown
                    id={reportsData.dropdownId}
                    classes='quarter'
                    label={t.selectedReport}
                    hideErrors={true}
                    data={performanceReports}
                    callback={onReportSelect}/>
            </Card>
            {reportPages[currentReport]}
        </Page>
    )
}

export default Reports;
