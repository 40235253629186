export const userDetailsLayout = {
    id: 'user-details',

    validators: [
        {
            id: 'firstName',
            checks: ['required'],
        },
        {
            id: 'lastName',
            checks: ['required'],
        },
        {
            id: 'email',
            checks: ['required', 'email'],
        },        
        {
            id: 'companyId',
            checks: ['required'],
        },  
        {
            id: 'phone',
            checks: ['phone'],
        },
        {
            id: 'address-zipCode',
            checks: ['zip'],
        },
    ]
}
