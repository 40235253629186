import './devices.scss';
import { useEffect, useRef, useState } from 'react';
import { devicesLayout as layout } from './DevicesLayout';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const Devices = () => {
    const { devicesTabs, refreshPage, setRefreshPage } = useProfile();
    const { callAPI, updateDevicesTabs } = useUtils();
    const [ tableData, setTableData ] = useState();
    const [ tableLoading, setTableLoading ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('devices', devicesListCallback);
        updateDevicesTabs();
    }, [])

    useEffect(() => {
        if (refreshPage) {
            setTableLoading(true);
            callAPI('devices', devicesListCallback);
        }
    }, [refreshPage])

    const devicesListCallback = (data) => {
        setTableLoading(false);
        setTableData(data?.list || []);
        setRefreshPage(false);
    }
    
    return (
        <Page
            page={t.devices}
            subPage={t.all}
            tabs={devicesTabs}
        >
            <Table
                data={tableData}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default Devices;
