import { text as t } from 'shared/text';

export const devicesGRCPOSLayout = {
    id: 'devices-grc-pos',

    defaultSort:  {
        prop: 'id',
        desc: false,
    },

    tableHeaders: {
        checkbox: '',
        id: t.deviceId,
        machineSerialNumber: t.serialNumber,
        locationName: t.locationName,
        isOnline: t.online
    },

    sizes: {
        checkbox: 'icon',
        id: 'mid',
        machineSerialNumber: 'mid',
        locationName: 'mid',
        isOnline: 'small',
    },

    layouts: {
        id: 'text-left',
        machineSerialNumber: 'text-left',
        locationName: 'text-left',
    },

    formats: {
        isOnline: 'indicator'
    },

    navigation: {
        id: {
            label: 'id',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'id'},
            ]
        },
        locationName: {
            label: 'locationName',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'},
            ]
        }
    },

    hideSortArrows: {
        checkbox: true,
    },

    contextMenu: [
        'reboot',
        'pauseService',
        'startService',
    ]
}
