import './tableNav.scss';
import { useEffect, useRef, useState } from 'react';
import { deepCopy, objectEmpty } from 'components/shared/componentUtils';
import { clearSelected } from 'app-components/Table/tableUtils';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';

const TableNav = ({layout, data}) => {
    const { tableStates, setTableStates } = useProfile();
    const [ pageButtons, setPageButtons ] = useState();
    const [ disableLeft, setDisableLeft ] = useState(true);
    const [ disableRight, setDisableRight ] = useState(true);
    const firstBuild = useRef(true);
    const id = layout.id;
    const navId = id + '-table-nav';
    const navStyle = layout.navStyle;

    useEffect(() => {
        if (!tableStates?.[id] || !data) {return}
        const pageCount = Math.ceil(data.length / tableStates[id].rowCount);
        if (!pageCount) {
            setPageButtons([<div key={0} className="no-table-nav-buttons">{t.nullVal}</div>]);
        }
        else {
            setPageButtons(pageCount > 5 ? createShortenedPages(pageCount) : createAllPages(pageCount));
        }
    }, [data, tableStates?.[id]]);

    useEffect(() => {
        if (!tableStates?.[id] || objectEmpty(tableStates)) {
            return;
        }
        const page = tableStates?.[id]?.currentPage?.[0] ? tableStates?.[id]?.currentPage[0] : 1;
        updateNavState(page);
    }, [pageButtons]);

    const createAllPages = (pageCount) => {
        if (pageCount) {
            const array = [];
            for (let i = 1; i <= pageCount; i++) {
                array.push(i);
            }
            return (array.map((i) => {
                return createNavButton(i);
            }));
        }
    };

    const createShortenedPages = (pageCount) => {
        const currentPage = tableStates?.[id]?.currentPage[0] || 1;
        const array = [];
        currentPage - 1 >= 2 && array.push(createNavButton(1));
        currentPage - 1 >= 3 && array.push(<div className='table-nav-concat' key='concat1'>...</div>);
        currentPage - 1 >= 1 && array.push(createNavButton(currentPage - 1));
        array.push(createNavButton(currentPage));
        currentPage + 1 <= pageCount && array.push(createNavButton(currentPage + 1));
        currentPage + 3 <= pageCount && array.push(<div className='table-nav-concat' key='concat2'>...</div>);
        currentPage + 2 <= pageCount && array.push(createNavButton(pageCount));
        disableArrows(currentPage)
        return array;
    };

    const createNavButton = (val) => {
        return (<div
            key={val}
            id={id + 'nav-num' + val}
            value={val}
            className={`table-nav-number table-nav-button ${firstCurrent(val)}`}
            onClick={(event) => {
                navToSet(event);
            }}
        >{val}</div>);
    };

    const moveToEnd = (place) => {
        if (place === 'end') {place = tableStates?.[id]?.pageCount};
        if (place === tableStates?.[id].currentPage[0]) {return};
        loadNewPage(place);
    };

    const movePage = (num) => {
        const currentPage = tableStates?.[id].currentPage[0];
        if (num > 0 && currentPage === tableStates?.[id]?.pageCount) {return};
        if (num < 0 && currentPage === 1) {return};
        const newPage = currentPage + num;
        loadNewPage(newPage);
    };
    const navToSet = (event) => {
        const target = event.target;
        const parent = target.parentElement;
        const currentPage = +parent.getElementsByClassName('current')[0].getAttribute('value');
        const newPage = +target.getAttribute('value');
        if (currentPage === newPage) {return};
        loadNewPage(newPage);
    };

    const loadNewPage = (page) => {
        let newRange;
        if (tableStates?.[id]?.pageCount > 5) {
            const currentRange = tableStates?.[id]?.range;
            if (page < currentRange[0]) {
                newRange = [page, page + 1];
            }
            else if (page > currentRange[1]) {
                newRange = [page - 1, page];
            }
        }
        const newStates = deepCopy(tableStates);
        if (newRange) { newStates[id].range = newRange };
        newStates[id].currentPage = [page];
        newStates[id].selected = '';
        newStates[id].multiCheck = [];
        newStates[id].checkAll = false;
        setTableStates(newStates);
        clearSelected(id);
    };

    const updateNavState = (page) => {
        removeCurrent();
        addCurrent(page);
    };

    const removeCurrent = () => {
        const nav = document.getElementById(navId);
        const nums = nav.getElementsByClassName('table-nav-number');
        const length = nums.length;
        for (let i = 0; i < length; i++) {
            nums[i].classList.remove('current');
        }
    };

    const addCurrent = (page) => {
        document.getElementById(id + 'nav-num' + page)?.classList.add('current');
    };

    const disableArrows = (page) => {
        if (!tableStates[id]) {return };
        const pages = Math.ceil(data?.length / tableStates?.[id].rowCount);
        const usedNum = getPages(pages);
        page === 1 ? setDisableLeft(true) : setDisableLeft(false);
        page === usedNum ? setDisableRight(true) : setDisableRight(false);
    };

    const firstCurrent = (val) => {
        if (val === 1 && firstBuild.current === true) {
            firstBuild.current = false;
            return 'current';
        }
        else {
            return '';
        }
    };

    const getPages = (pages) => {
        if (pages === undefined) {
            return tableStates?.[id]?.pageCount;
        }
        else if (pages === 0) {
            return 1;
        }
        else {
            return pages;
        }
    };

    return (
        <div id={navId} className={`table-nav-container ${navStyle ? navStyle : ''}`}>
            {layout.hideNav ? '' : <>
                <div className={`table-nav-button first end ${disableLeft ? 'disabled' : ''}`} onClick={() => {
                    moveToEnd(1);
                }}>{t.first}</div>
                <div className={`table-nav-button direction ${disableLeft ? 'disabled' : ''}`} onClick={() => {
                    movePage(-1);
                }}>{!navStyle === 'arrow' ? t.previous : '<'}</div>
                <div className='table-nav-numbers'>{pageButtons}</div>
                <div className={`table-nav-button direction ${disableRight ? 'disabled' : ''}`} onClick={() => {
                    movePage(1);
                }}>{!navStyle === 'arrow' ? t.next : '>'}</div>
                <div className={`table-nav-button last end ${disableRight ? 'disabled' : ''}`} onClick={() => {
                    moveToEnd('end');
                }}>{t.last}</div>
            </>}
        </div>
    );
};

export default TableNav;
