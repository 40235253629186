import { useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { componentText as t } from 'components/shared/componentData';
import useInputs from '../Inputs/useInputs';
import useModal from '../Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useTables from '../../Tables/TableParts/TableHooks/useTables';
import ComponentsContext from './ComponentsContext';

const useComponents = () => {
    const { noticeContent, setNoticeContent,
            noticeState, setNoticeState, 
            noticeDefaultTop, setNoticeDefaultTop,
            tooltipData, setTooltipData } = useContext(ComponentsContext);
    const { clearAllInputs } = useInputs();
    const { removeModal } = useModal();
    const { size, openMainMenu, openPageMenu } = useProfile();
    const { clearAllTables } = useTables();
    const changeNoticeContent = (a) => {setNoticeContent(a)};
    const changeNoticeState = (a) => {setNoticeState(a)};
    const changeNoticeDefaultTop = (a) => {setNoticeDefaultTop(a)};
    const changeTooltipData = (a) => {setTooltipData(a)};
    const lastNav = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    // NAVIGATION
    const navTo = (path, commands) => {
        const lastURL = {url: window.location.href}
        const queries = window.location.search
        if (queries) {
            const [key, value] = new URLSearchParams(queries).entries().next().value;
            lastURL.id = key;
            lastURL.props = JSON.parse(value);
        }
        sessionStorage.setItem('lastURL', JSON.stringify(lastURL));
        clearAllInputs();
        clearAllTables();
        if (size === 'phone') {
            openMainMenu(false);
            openPageMenu(false);
        }
        removeModal();
        navigate(path, { state: { from: location }, replace: commands?.includes('replace') ? true : false })
    }
    
    // NOTICE POPUP
    const updateNotice = (state, message, event) => {
        if (event) {
            updateNoticeLocation(event.target);
        } else {
            resetNoticeLocation();
        }
        updateNoticeData(state, message); 
    }

    const updateNoticeData = (state, message) => {
        if (message) {
            setNoticeState(state);
            setNoticeContent(message);
        } else {
            switch (state) {
                case 'fail':
                    setNoticeState(state);
                    setNoticeContent(t.updateFail);
                    break; 
                case 'printFail': 
                    setNoticeState('fail');
                    setNoticeContent(t.printFail);
                    break;
                case 'printSuccess': 
                    setNoticeState('success');
                    setNoticeContent(t.printSucess);
                    break;
                default:
                    setNoticeState(state);
                    setNoticeContent(t.updateSuccess);
                    break;
            }  
        } 
    }

    const updateNoticeLocation = ( target ) => {
        const message = document.getElementById('notice-message');
        const position = target.getBoundingClientRect();
        message.style.left = (position.left + (target.clientWidth / 2)) + 'px';
        message.style.top = (position.top + -message.clientHeight - 15) + 'px';
    }

    const resetNoticeLocation = () => {
        const message = document.getElementById('notice-message');
        message.style.left = '50%';
        message.style.top = noticeDefaultTop + 'px';
    }

    return { 
        lastNav, navTo,
        noticeContent, changeNoticeContent,
        noticeState, changeNoticeState,
        noticeDefaultTop, changeNoticeDefaultTop,
        tooltipData, changeTooltipData,
        updateNotice
    }
}

export default useComponents;
