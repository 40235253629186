import './locationPlayers.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { locationPlayersLayout as layout } from './LocationPlayersLayout';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import TableListed from 'components/Tables/TableListed';
import LocationPlayerHistoryModal from 'modals/FlowModals/LocationPlayerHistoryModal/LocationPlayerHistoryModal';

const LocationPlayers = () => {
    const { id } = useParams();
    const { addModal } = useModal();
    const { setHeader, updateLocationBreadcrumbs, 
            locationTabs, updateLocationHeader} = useProfile();
    const { callAPI, updateLocationTabs } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ masked, setMasked ] = useState(true);    const tableId = 'location-players-' + id;
    const runOnce = useRef();
    layout.navigation.playerPin.onClick = (pageId, rowData) => {addModal(<LocationPlayerHistoryModal locationId={id} rowData={rowData}/>)}

    useEffect(() => {
        if (runOnce.current) {return}
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        getReportData();
    }, []);

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        details && setHeader(details.locationName);
        updateLocationBreadcrumbs(details);
        const types = data?.kioskTypes;
        updateLocationTabs(types); 
        updateLocationHeader(data?.locationDetails);
    }

    const getReportData = () => {
        setMasked(true);
        const params = {locationId: id}
        callAPI('report-LocationPlayers', playerBalancesCallback, params);
    }

    const playerBalancesCallback = (data) => {
        setTableRecords(data?.playerBalances || []);
        setMasked(false);
    }

    return (
        <Page
            subPage={t.players}
            tabs={locationTabs.tabs}
        >
            <TableListed
                id={tableId}
                classes='full'
                data={tableRecords}
                layout={layout}
                masked={masked?.[0]}
            />
        </Page>
    )
}

export default LocationPlayers;
