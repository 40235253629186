import './userDetails.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { userDetailsLayout as layout } from './UserDetailsLayout';
import { deepCopy } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const UserDetails = ({newUser, newSubmit, newData, newCancel}) => {
    const { id } = useParams();
    const { getAuth } = useAuth();
    const { reNestedData, updateNestedInputData,
            changeInputErrors, updateInputErrors,
            changeCurrentInput, validateForm } = useInputs();
    const { setModal } = useModal();
    const { setHeader, userTabs } = useProfile();
    const { callAPI, callAPIPatch,
            hasRank, updateUserTabs, 
            updateNotice } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ states, setStates ] = useState();
    const [ companies, setCompanies ] = useState();
    const [ masked, setMasked ] = useState(true);    const defaultSet = useRef(false);
    const permitted = hasRank('owner');

    useEffect(() => {
        updateUserTabs()
        callAPI('statesGet', statesGetCallback);
        callAPI('companies', companiesCallback);
        if (newData) {
            setPageData(newData)
        } else {
            if (!newUser) {
                defaultSet.current = false;
                const params = {id: id};
                callAPI('userDetails', userDetailsCallback, params, params);
            } else {
                setPageData({})
            }
        }
    }, [id])

    useEffect(() => {
        if (pageData && companies && !defaultSet.current) {
            defaultSet.current = true
            const dropdownList = {
                companyId: {list: 0},
                ['address-state']: {list: 1, labelProp: 'stateCode'}
            }
            if (newUser && !newData) {
                pageData.id = -1;
                pageData.companyId = companies[0].id;
            }
            const update = updateNestedInputData(pageData, dropdownList, [companies, states]);
            update && setMasked(false);
        }
    }, [pageData, companies])

    const statesGetCallback = (data) => {
        setStates(data?.states ? data.states : [])
    }

    const companiesCallback = (data) => {
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const userDetailsCallback = (data ) => {
        const details = data?.webPortalUser;
        if (details) {
            if (!newUser) {
                const header = getAuth().id === id ? t.yourProfile : details.name ? details.name : details.email;
                setHeader(header);
            } else if (details.email === 'default.web.portal.user@primero.com') {
                details.email = ''
            }
            setPageData(details);
        } else {
            setModal(false);
            updateNotice(data);
            setMasked(false)
        }
    };

    const onEmailBlur = (data) => {
        data.value && callAPI('emailUniqueCheck', uniqueBlurCallback, {email: data.value});
    }

    const uniqueBlurCallback = (data) => {
        !data.isUnique && updateInputErrors('email', t.emailTaken);
    }

    const onSubmit = ( event, setLoading ) => {
        const params = reNestedData(pageData);
        params.name = params.firstName + ' ' + params.lastName;
        params.isActive = true;
        if (!params.address) {params.address = {}}
        params.address.country = '';
        if (params?.address?.state) {params.address.state = params.address.state.toString()}
        const props = {
            event: event,
            setLoading: setLoading
        };
        const short = deepCopy(layout.validators);
        short.shift();
        const errorsList = validateForm(newUser ? layout.validators : short);
        if (newUser && params.email && !errorsList?.email) {
            props.errorsList = errorsList;
            props.params = params;
            props.setLoading(true);
            callAPI('emailUniqueCheck', emailUniqueCheckCallback, {email: params.email}, props);
        } else {
            submitUser(params, props, errorsList);
        }
    }

    const emailUniqueCheckCallback = (data, props) => {
        let newErrors = props.errorsList;
        if (data?.isSuccessful) {
            if (!data.isUnique) {
                newErrors = props.errorsList ? props.errorsList : {};
                newErrors.email = t.emailTaken;
            }
            submitUser(props.params, props, newErrors);
        } else {
            newErrors.email = t.emailUnchecked;
            submitUser(props.params, props, newErrors);
        }
    }

    const submitUser = (params, props, errorsList) => {
        if (errorsList) {
            props.setLoading(false);
            changeInputErrors(errorsList);
            return;
        }
        props.setLoading(true);
        if (newUser) {
            defaultSet.current = false;
            changeCurrentInput('');
            newSubmit(params);
        } else {
            callAPIPatch('userUpdate', userUpdateCallback, params, props);
        }
    }

    const userUpdateCallback = (data, props) => {
        props.setLoading(false);
        updateNotice(data, props);
    }

    const onCancel = () => {
        newCancel();
    }

    return (
        <Page
            subPage={newUser ? '' : t.details}
            tabs={newUser ? [] : userTabs }
            contentClasses='grid'
        >
            <Card hideLabel={true} classes='full margin-top'>
                <Input  label={t.firstName} line='firstName' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.lastName} line='lastName' classes='half'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Input  classes='quarter' line='title' masked={masked?.[0]}
                        label={t.title}  noPermission={!permitted}/>
                <Input  label={t.email} line='email' classes={`quarter ${!newUser ? 'display-only' : ''}`}
                        masked={masked?.[0]} noPermission={!permitted} onBlurCallback={onEmailBlur}/>
                <Input  label={t.phoneNumber} line='phone' type='phone' classes='quarter'
                        masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown label={t.company} line='companyId' classes='quarter'
                        placeholder={t.selectOne} masked={masked?.[0]} noPermission={!permitted} data={companies}/>

                <Input  label={t.address} section='address' line='streetLine1' classes='half'
                        type='allCaps' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.addressTwo} section='address' line='streetLine2'  classes='half'
                        type='allCaps' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.city} section='address' line='city' classes='half'
                        type='allCaps' masked={masked?.[0]}  noPermission={!permitted}/>
                <Dropdown label={t.state} section='address' line='state' classes='half' data={states}
                        labelProp='stateCode' masked={masked?.[0]} noPermission={!permitted}/>
                <Input  label={t.zip} section='address' line='zipCode' classes='quarter'
                        type='zip' masked={masked?.[0]} noPermission={!permitted}/>

            </Card>
            {newUser && <div className='full modal-buttons-container grid'>
                <Button
                    classes='red quarter'
                    onClick={onCancel}
                    >{t.cancel}
                </Button>
                <Button
                    classes='green quarter last' 
                    type='submit'
                    onClick={( event, setLoading ) => onSubmit(event, setLoading)}
                    >{t.next}
                </Button>
            </div> }
            {!newUser && permitted && <Button
                classes='green quarter last' 
                type='submit'
                onClick={( event, setLoading ) => onSubmit(event, setLoading)}
                >{newUser ? t.next : t.submit}
            </Button>}
        </Page>
    )
}

export default UserDetails;
