import './cashDisplay.scss';
import { useEffect, useState } from 'react';
import { getCashData } from 'shared/utils';
import { toCurrency } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';

const CashDisplay = ({title, classes, data, countryCode}) => {
    const [ cashData, setCashData ] = useState();
    const [ grandTotal, setGrandTotal ] = useState(0);
    useEffect (() => {
        if(!data) {return};
        const newData = getCashData(data, countryCode);
        setCashData(newData);
        let sum = 0;
        newData.forEach((item)=> {sum += item.value});
        setGrandTotal(sum);
    }, [data, countryCode]);

    return <div className={`cash-display-container ${classes ? classes : ''}`}>
        <div className='cash-display grid'>
            {title && <div className='cash-display-title full'>{title}</div>}
            {cashData && cashData.map((item, index) => (
                <div key={index} className='cash-display-row full grid'>
                    <div className='quarter'>{item.denomination}</div>
                    <div className='quarter right'>{item.count}</div>
                    <div className='half right'>{toCurrency(item.value, countryCode)}</div>
                </div>
            ))}
            <div className='divider full'/>
            <div className='half bold'>{t.total}</div><div className='half bold right'>{toCurrency(grandTotal, countryCode)}</div>
        </div>
    </div>
}

export default CashDisplay;