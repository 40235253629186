import './locations.scss';
import { useEffect, useRef, useState} from 'react';
import { locations as layout } from './LocationsLayout'; 
import useUtils from 'hooks/useUtils';
import useProfile from 'hooks/Profile/useProfile';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const Locations = () => {
    const { refreshPage, setRefreshPage } = useProfile();
    const { callAPI } = useUtils();
    const [ locations, setLocations ] = useState();
    const [ tableLoading, setTableLoading ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        callAPI('locations', locationsCallback);
    }, [])

    useEffect(() => {
        if (refreshPage) {
            setTableLoading(true);
            callAPI('locations', locationsCallback);
        }
    }, [refreshPage])

    const locationsCallback = (data) => {       
        setTableLoading(false);
        setLocations(data?.list || []);
        setRefreshPage(false);
    }

    return (
        <Page page={layout.page}>
            <Table 
                data={locations}
                layout={layout} 
                classes='full'
                loading={tableLoading}
            />
        </Page>
    )
}

export default Locations;