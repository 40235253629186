import './userPassword.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { deepCopy, hasLower, toPin,
         hasNumber, hasUpper,
         hasValidSpecial, hasInvalidSpecial } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';
import ChangePinModal from 'modals/FlowModals/ChangePinModal/ChangePinModal';

const UserPassword = ({newUser, newSubmit, newBack}) => {
    const { id } = useParams();
    const { getAuth } = useAuth();
    const { inputValues, changeInputValues, clearAllInputs } = useInputs();
    const { addModal, removeModal } = useModal();
    const { setHeader, userTabs } = useProfile();
    const { callAPI, hasRank, 
            updateUserTabs, updateNotice } = useUtils();
    const [ showPin, setShowPin ] = useState();
    const [ pinNumber, setPinNumber ] = useState();
    const [ canChangePin, setCanChangePin ] = useState();
    const [ validPassword, setValidPassword ] = useState();
    const showPinTimer = useRef();
    const yourProfile = getAuth()?.id === id;
    const permitted = hasRank('owner');
    const passwordId = 'user-password';
    const confirmId = 'user-password-confirm';

    useEffect(() => {
        updateUserTabs();
        callAPI('userDetails', userDetailsCallback, {id: id});
        return () => {clearAllInputs()};
    }, []);

    useEffect(() => {
        const val = inputValues?.[passwordId] ? inputValues[passwordId] : '';
        let invalid = 0;
        const newValues = deepCopy(inputValues);
        if (val.length >= 8) { newValues.minLength = true } else {
            newValues.minLength = false;
            invalid ++;
        }
        if (hasUpper(val)) { newValues.oneUppercase = true } else {
            newValues.oneUppercase = false;
            invalid ++;
        }
        if (hasLower(val)) { newValues.oneLowercase = true } else {
            newValues.oneLowercase = false;
            invalid ++;
        }
        if (hasNumber(val)) { newValues.oneNumber = true } else {
            newValues.oneNumber = false;
            invalid ++;
        }
        if (hasValidSpecial(val)) { newValues.oneSpecial = true } else {
            newValues.oneSpecial = false;
            invalid ++;
        }
        if (!hasInvalidSpecial(val)) { newValues.invalidSpecial = false } else {
            newValues.invalidSpecial = true;
            invalid ++;
        }
        newValues.passwordsMatch = val && inputValues[confirmId] && val === inputValues[confirmId]
        changeInputValues(newValues);
        setValidPassword(invalid === 0 ? true : false);
    }, [inputValues[passwordId], inputValues[confirmId]])

    useEffect(() => {
    }, [inputValues])

    const userDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const user = data.webPortalUser;
            setPinNumber(toPin(user.pinNumber));
            setCanChangePin(user.canChangePin);
            const header = yourProfile ? t.yourProfile : user.name ? user.name : user.email;
            setHeader(header);
        }
    }

    const onSubmit = (event, setLoading) => {
        const newPassword = inputValues[passwordId];
        const newConfirm = inputValues[confirmId];
        setLoading(true);
        const params = {
            userId: id ? id : newUser,
            password: newPassword,
            confirmation: newConfirm,
        };
        if (newUser) {
            newSubmit(params)
        } else {
            const props = {
                event: event,
                setLoading: setLoading,
                userId: id ? id : newUser,
            }
            callAPI('userPasswordSet', userPasswordSetCallback, params, props);
        }
    }

    const userPasswordSetCallback = (data, props) => {
        props.setLoading(false);
        clearAllInputs();
        if (!newUser) {
            updateNotice(data, props);
        } else {
            newSubmit(props);
        }
    }

    const onShowPinClick = () => {
        if (showPin) {
            clearTimeout(showPinTimer.current);
            setShowPin(false);
        } else {
            setShowPin(true);
            showPinTimer.current = setTimeout(()=>{
                setShowPin(false);
            }, 10000)
        }
    }

    const onChangePinClick = () => {
        addModal(<ChangePinModal id={id} callback={changeSuccessCallback}/>)
    }

    const changeSuccessCallback = (newPin) => {
        if (newPin) {
            setPinNumber(newPin);
        } else {
            callAPI('userDetails', userDetailsCallback, {id: id});
        }
        removeModal();
    }

    return (
        <Page
            classes='user-password'
            subPage={newUser ? '' : t.password}
            tabs={newUser ? [] : userTabs}
            contentClasses='grid'
        >
            <Card classes={`${newUser ? 'centered five-sixths' : 'half'}`} label={t.setPassword}>
                <form className='full grid'>
                    <Input classes='full' id='user-password' type='password' placeholder={t.enterPassword}
                        label={t.enterPassword} noPermission={!permitted}/>
                    <div className='sixth'></div>
                    <div className='password-reqs-container grid two-thirds'>
                        <Checkbox classes='half left password-req' id='minLength' displayOnly={true} label='8 character minimum' />
                        <Checkbox classes='half left password-req' id='oneUppercase' displayOnly={true} label='one uppercase' />
                        <Checkbox classes='half left password-req' id='oneLowercase' displayOnly={true} label='one lowercase' />
                        <Checkbox classes='half left password-req' id='oneNumber' displayOnly={true} label='one number' />
                        <Checkbox classes='half left password-req' id='oneSpecial' displayOnly={true} label='one special character' />
                        {inputValues?.invalidSpecial && <Checkbox classes='half password-req' id='invalidSpecial' showFail={true} displayOnly={true} label='invalid character'/>}
                    </div>
                    <Input  classes='full' id='user-password-confirm' type='password' placeholder={t.confirmPassword}
                            label={t.confirmPassword} noPermission={!permitted} hideErrors={true}/>
                    <div className='sixth'></div>
                    <div className='password-reqs-container grid two-thirds'>
                        <Checkbox id='passwordsMatch' classes='half left password-req' displayOnly={true} label='passwords match' />
                    </div>
                </form>
                {!newUser && <Button classes='green last half'
                    type='submit'
                    disabled={!validPassword || !inputValues.passwordsMatch}
                    onClick={(event, setLoading) => onSubmit(event, setLoading)}
                    >{t.save}
                </Button>}
            </Card>
            {pinNumber && hasRank('all') && <Card classes={`half first ${newUser ? 'centered' : ''} margin-top`} label={t.adminPinManage}>
                {canChangePin ? <><Button classes='half blue'
                    disabled={pinNumber === '99-99-99-99-99-99'}
                    onClick={onShowPinClick}
                    >{showPin ? pinNumber : t.showPin}
                </Button>
                <Button classes='half blue'
                    onClick={onChangePinClick}
                    >{pinNumber === '99-99-99-99-99-99' ? t.adminPinCreate : t.adminPinChange }
                </Button></>
                : <div className='no-pin-changing-message full'>{t.needGRCKiosk}</div>}
            </Card>}
            {newUser && <div className='full modal-buttons-container grid'>
                <Button classes='red quarter'
                    onClick={newBack}
                    >{t.back}
                </Button>
                <Button classes='green quarter last'
                    type='submit'
                    disabled={!validPassword || !inputValues.passwordsMatch}
                    onClick={(event, setLoading) => onSubmit(event, setLoading)}
                    >{t.next}
                </Button>
            </div>}
        </Page>
    )
}

export default UserPassword;
