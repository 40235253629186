import 'pages/Companies/CompanyLocations/companyLocations.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { companyLocationsLayout as layout } from './CompanyLocationsLayout';
import { companyTabsList } from 'shared/tabs';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';
import { text as t } from 'shared/text';

const CompanyLocations = () => {
    const { id } = useParams();
    const { setHeader } = useProfile();
    const { callAPI, callAPIGet } = useUtils();
    const [ companyLocations, setCompanyLocations ] = useState();
    const [ tableLoading, setTableLoading ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = {companyId: id};
        callAPIGet('locationsGetByCompanyId', locationCallback, params);
        callAPI('companyDetails', detailsCallback, {id: id})
    }, []);

    const locationCallback = (data) => {
        setCompanyLocations(data?.locations || []);
        setTableLoading(false);
    }

    const detailsCallback = (data) => {
        const name = data?.companyDetailsData?.companyInfo?.companyName;
        setHeader(name ? name : '');
    }

    return (
        <Page
            subPage={t.locations}
            tabs={companyTabsList}
        >
            <Table
                data={companyLocations}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default CompanyLocations;
