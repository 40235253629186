import { text as t } from 'shared/text'

export const locationAuditLayout = {
    tableHeaders: {
        id: t.transactionId,
        locationId: t.locationId,
        terminalId: t.terminalId,
        pinNumber: t.user,
        transactionType: t.transactionType,
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        createdOn: t.createdOn
    },

    sizes: {
        id: 'mid',
        locationId: 'mid',
        terminalId: 'mid',
        pinNumber: 'small',
        transactionType: 'mid',
        cashIn: 'mid',
        cashOut: 'mid',
        createdOn: 'mid'
    },
    
    layouts: {
        id: 'text-left',
        locationId: 'text-left',
        terminalId: 'text-left',
        pinNumber: 'text-left',
        transactionType: 'text-left',
        cashIn: 'blank text-right',
        cashOut: 'blank text-right', 
        createdOn: 'text-left',
    },

    formats: {
        pinNumber: 'lastFour',
        createdOn: 'standard-date',
        cashIn: 'currency',
        cashOut: 'currency', 
    },

    navigation: {
        locationId: {
            label: 'locationId',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'}
            ]
        },
        terminalId: {
            label: 'terminalId',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'terminalId'}
            ]
        }
    },

    subtotals: {
        groupBy: 'all',
        fields: {
            id: 'header',
            cashIn: 'total',
            cashOut: 'total',
        }, 
        layouts: {
            id: 'text-left',
            cashIn: 'text-right',
            cashOut: 'text-right',
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
        }
    }
}
