import './mainMenu.scss';
import { useLocation } from 'react-router-dom';
import { mainMenuLayout } from './MainMenuLayout';
import { text as t } from 'shared/text';
import logo from 'shared/img/primero-logo.png';
import useAuth from 'hooks/Auth/useAuth';
import useComponents from 'components/hooks/Components/useComponents';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import MenuItem from './MenuItem/MenuItem';
import ConfirmModal from 'modals/FlowModals/ConfirmModal/ConfirmModal';

const MainMenu = () => {
    const { callLogout, getAuth } = useAuth();
    const { navTo } = useComponents(); 
    const { addModal } = useModal();
    const { size, mainMenuOpen, openMainMenu } = useProfile();
    const { hasRank } = useUtils();

    const location = useLocation();

    const onLogoClick = () => {
        navTo('/');
    }

    const onLogout = () => {
        addModal(<ConfirmModal header={t.loggingOut} content={t.confirmLogout} onConfirm={callLogout} remainOpen={true}/>)
    }

    const onToggleClick = () => {
        openMainMenu(!mainMenuOpen);
    }

    const buildMainMenu = () => {
        return <>{mainMenuLayout.map(( props, i ) => {
            return buildMenuItem(props, i);
        })}
        <MenuItem
            icon='bi-person-circle'
            classes='profile'
            buildMenuItem={buildMenuItem}
            children={[
                {
                    label: t.yourCompany,
                    icon: 'bi-building',
                    path: '/company/' + getAuth()?.companyId,
                    rank: 'pullTabsVendor',
                },
                {
                    label: t.yourProfile,
                    icon: 'bi-person-circle',
                    path: '/user/' + getAuth()?.id,
                    rank: 'pullTabsVendor',
                },
                {
                    label: t.logout,
                    icon: 'bi-door-open',
                    rank: 'pullTabsVendor',
                    callback: onLogout
                }
            ]}
        /></>
    }

    const buildMenuItem = (props, i) => {
        if (!hasRank(props.rank)) {return}
        return <MenuItem
            key={i}
            icon={props.icon}
            label={props.label}
            path={props.path}
            buildMenuItem={buildMenuItem}
            children={props.children}
            callback={props.callback}
        />
    }

    return (!location.pathname.includes('login') &&
        !location.pathname.includes('forgot-password') &&
        !location.pathname.includes('reset-password') && <div className='main-menu-container'>
            <div className='logo' onClick={onLogoClick}>
                <img className='logo-image' src={logo} alt='logo'/>
            </div>
            {size === 'phone' ? <div className='main-menu-toggle-container'>
                <div className='main-menu-toggle'>
                    <Button classes='main-menu-toggle'
                        icon='list' 
                        onClick={onToggleClick}
                    />
                    {<div id='main-menu' className={`main-menu ${mainMenuOpen ? 'open' : ''}`}>
                        {buildMainMenu()}
                    </div>}
                </div>
            </div>
            : <div id='main-menu' className={`main-menu`}>
                {buildMainMenu()}
            </div>}
        </div>)
}

export default MainMenu;
