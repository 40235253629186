import { text as t } from 'shared/text';

export const devicesPrimePayLayout = {
    id: 'devices-primepay',
    page: t.devices,
    subpage: t.primePay,

    defaultSort:  {
        prop: 'id',
        desc: false,
    },

    tableHeaders: {
        id: t.deviceId,
        locationName: t.locationName,
        cassettes: [t.cassette],
    },

    sizes: {
        id: 'mid',
        locationName: 'mid',
        cassettes: 'mid',
    },

    layouts: {
        id: 'text-left',
        locationName: 'text-left',
        cassettes: 'text-left',
    },

    navigation: {
        id: {
            label: 'id',
            data: [
                {type: 'text', val: 'device'},
                {type: 'prop', val: 'id'}
            ]
        },
        locationName: {
            label: 'locationName',
            data: [
                {type: 'text', val: 'location'},
                {type: 'prop', val: 'locationId'}
            ]
        },
    },

    hideSortArrows: {
        cassettes: true,
    }
}
