import './locationDevices.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { locationDevicesLayout as layout } from './LocationDevicesLayout';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const LocationDevices = () => {
    const { id } = useParams();
    const { locationTabs, updateDeviceBreadcrumbs, updateLocationHeader } = useProfile();
    const { callAPI, updateLocationTabs } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ tableLoading, setTableLoading ] = useState(true);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = { locationId: id }
        const detailParams = { id: id }
        callAPI('locationDevices', locationDevicesCallback, params);
        callAPI('locationDetails', locationDetailsCallback, detailParams);
    }, []);

    const locationDetailsCallback = (data) => {
        updateLocationTabs(data?.kioskTypes);
        updateDeviceBreadcrumbs(data?.locationDetails);
        updateLocationHeader(data?.locationDetails);
    }

    const locationDevicesCallback = (data) => {
        setTableLoading(false);
        setPageData(data?.data || []);
    }

    return (
        <Page
            subPage={t.devices} 
            tabs={locationTabs.tabs}
        >
            <Table
                data={pageData}
                layout={layout}
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default LocationDevices;
