import {text as t} from 'shared/text';

export const companyDevicesLayout = {
    tableHeaders: {
        id: t.deviceId,
        machineSerialNumber: t.serialNumber,
        locationName: t.locationName,
        collector: t.assignedUser,
        terminalTypeName: t.type,
        transactions: t.transactions,
    },

    sizes: {
        id: 'mid',
        machineSerialNumber: 'mid',
        locationName: 'mid',
        collector: 'large',
        terminalTypeName: 'mid',
        transactions: 'mid',
    },

    layouts: {
        id: 'text-left',
        machineSerialNumber: 'text-left',
        locationName: 'text-left',
        collector: 'text-left',
        terminalTypeName: 'text-left',
    },

    navigation: {
        id: {
            root: 'device',
            label: 'id',
            value: 'id',
        },
        locationName: {
            root: 'location',
            label: 'locationName',
            value: 'locationId'
        },
        collector: {
            root: 'user',
            label: 'collector.label',
            value: 'collector.id'
        },
        transactions: {
            root: 'device',
            value: 'transactions.id',
            label: 'transactions.icon',
            ending: 'transactions',
        }
    },

    hideSortArrows: {
        transactions: true,
    },

    formats: {
        transactions: 'icon',
    },

    rowCount: 20,
}
