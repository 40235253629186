import './locationPlayerInfo.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { locationPlayerInfoLayouts as layout } from './LocationPlayerInfoLayouts';
import { text as t } from 'shared/text';
import useProfile from 'hooks/Profile/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TableListed from 'components/Tables/TableListed';

const LocationPlayerInfo = () => {
    const { id } = useParams();
    const { setHeader, updateLocationBreadcrumbs } = useProfile();
    const { updateTable } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ resultsVals, setResultsVals ] = useState();
    const [ masked, setMasked ] = useState(true);    const tableId = 'location-player-info-' + id;

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        getReportData(range);
        callAPI('locationDetails', locationDetailsCallback, {id: id});
    }, []);

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        updateLocationBreadcrumbs(details);
        details && setHeader(details.locationName);
    }

    const getReportData = (range) => {
        setMasked(true);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = id;
        callAPIGet('report-LocationPlayerInfo', playerInfoCallback, params);
    }

    const playerInfoCallback = (data) => {
        setResultsVals({
            activePlayers: data?.totalActivePlayers,
            newPlayers: data?.totalNewPlayers
        })
        setTableRecords(data?.data ? data.data : []);
        setMasked(false);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
    }

    return (<div className='full grid'>
       <div className='table-title full no-margin-top'>{t.playerInfo}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={masked?.[0]}
            resultsValues={resultsVals}
            searchBarContent={<>
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    callback={onDateRangePick}
                />
            </>}
        />
    </div>)
}

export default LocationPlayerInfo;
