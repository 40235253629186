import './companySettings.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deepCopy } from 'components/shared/componentUtils';
import { companyTabsList as tabs } from 'shared/tabs';
import { text as t } from 'shared/text';
import useAuth from 'hooks/Auth/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/Profile/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const CompanySettings = ({newCompany, newCancel}) => {
    const { id }  = useParams();
    const { getCountry } = useAuth();
    const { inputValues, inputErrors,
            changeInputValues, changeInputErrors, 
            updateNestedInputData, 
            reNestedData, clearAllInputs } = useInputs();
    const { removeModal } = useModal();
    const { setHeader } = useProfile();
    const { callAPI, callAPIGet,
            hasRank, updateNotice } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ alerts, setAlerts ] = useState();
    const [ masked, setMasked ] = useState(true);    const [ disabled, setDisabled ] = useState(false);
    const companyId = newCompany ? newCompany : id;
    const permitted = hasRank('owner', true);
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const country = useRef(getCountry());

    // None option covered by nullable in Dropdowns
    const alertRoles = [
        {contactId: 1, label: t.assignedUser},
        {contactId: 2, label: t.primaryContact},
        {contactId: 3, label: t.secondaryContact}
    ]

    useEffect(() => {
        if (runOnce.current && pageData?.id === companyId) {return}
        runOnce.current = true;
        const params = {id: newCompany ? -1 : id};
        callAPI('companyDetails', companyDetailsCallback, params);
        callAPIGet('companyAlertsGet', companyAlertsGetCallback, {companyId: companyId})
        return () => {clearAllInputs()};
    }, []);

    useEffect(()=> {
        if (alerts && !setOnce.current) {
            setOnce.current = true;
            const dropdownList = {
                ['firstContact-contactId']: {list: 0, valueProp: 'contactId', labelProp: 'label', nullable: t.none},
                ['secondContact-contactId']: {list: 0, valueProp: 'contactId', labelProp: 'label', nullable: t.none}
            }
            const update = updateNestedInputData(alerts, dropdownList, [alertRoles]);
            update && setMasked(false);
        }
    }, [alerts])

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues]);

    useEffect(()=>{
        if (!setOnce.current || !inputValues) {return};
        let newValues;
        if (!inputValues?.['firstContact-contactId'] && (inputValues['firstContact-textAlert'] || inputValues['firstContact-emailAlert'])) {
            newValues = deepCopy(inputValues);
            newValues['firstContact-textAlert'] = false;
            newValues['firstContact-emailAlert'] = false;
        }
        if (!inputValues?.['secondContact-contactId'] && (inputValues['secondContact-textAlert'] || inputValues['secondContact-emailAlert'])) {
            newValues = newValues ? newValues : deepCopy(inputValues)
            newValues['secondContact-textAlert'] = false;
            newValues['secondContact-emailAlert'] = false;
        }
        newValues && changeInputValues(newValues);
    }, [inputValues]);

    const companyDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const details = data?.companyDetailsData;
            setPageData(details);
            if (!newCompany) {
                setHeader(details.companyInfo?.companyName);
            }
        } else {
            setPageData({})
        }
    };

    const companyAlertsGetCallback = (data) => {
        if (data.isSuccessful) {    
            const {kioskAlerts, firstContact, secondContact} = data;
            setAlerts({kioskAlerts, firstContact, secondContact});
        }
    }

    const onSubmit = (event, setLoading) => {
        setLoading(true);
        setDisabled(true);
        changeInputErrors({});
        const params = reNestedData(alerts);
        params.companyId = companyId;
        if (!params.firstContact?.id) {params.firstContact.id = 0};
        if (!params.secondContact?.id) {params.secondContact.id = 0};
        const props = {event: event, setLoading: setLoading};
        callAPI('companyAlertsSet', companyAlertsSetCallback, params, props);
    }

    const companyAlertsSetCallback = (data, props) => {
        props.setLoading(false);
        setDisabled(false);
        if (newCompany && data.isSuccessful) {
            updateNotice(data);
            removeModal();
        } else {
            updateNotice(data, props);
        }
    }

    const onCancel = () => {
        newCancel();
    }

    return (
        <Page
            subPage={newCompany ? '' : t.settings}
            tabs={newCompany ? [] : tabs}
            contentClasses='grid'
        >
            {<Card label={t.grcAlerts} classes='half'>
                <Checkbox label={t.active} section='kioskAlerts' line='active' classes='third' noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTenUGD : t.alertForTen} section='kioskAlerts' line='lowRecycler10' classes='third'
                       type='max100' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTwentyUGD : t.alertForTwenty} section='kioskAlerts' line='lowRecycler20' classes='third' 
                       type='max100' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown label={t.primaryAlert} section='firstContact' line='contactId' classes='half' data={alertRoles} nullable={t.none}
                          valueProp='contactId' labelProp='name' appendProp='label' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <div className='grid half'>
                    <Checkbox label={t.alertText} section='firstContact' line='textAlert' classes={`half ${inputErrors?.['firstContact-checks'] ? 'error' : ''}`} disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['firstContact-contactId']} noPermission={!permitted}/>
                    <Checkbox label={t.alertEmail} section='firstContact' line='emailAlert' classes={`half ${inputErrors?.['firstContact-checks'] ? 'error' : ''}`} disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['firstContact-contactId']} noPermission={!permitted}/>
                    <div id='firstContact-checks' className='full contact-checks-errors'>{inputErrors?.['firstContact-checks']}</div>
                </div>
                <Dropdown label={t.secondaryAlert} section='secondContact' line='contactId' classes='half' data={alertRoles} nullable={t.none}
                          valueProp='contactId' labelProp='name' appendProp='label' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]}noPermission={!permitted}/>
                <div className='grid half'>
                    <Checkbox label={t.alertText} section='secondContact' line='textAlert' classes={`half ${inputErrors?.['secondContact-checks'] ? 'error' : ''}`} disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['secondContact-contactId']} noPermission={!permitted}/>
                    <Checkbox label={t.alertEmail} section='secondContact' line='emailAlert' classes={`half ${inputErrors?.['secondContact-checks'] ? 'error' : ''}`} disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['secondContact-contactId']} noPermission={!permitted}/>
                    <div id='secondContact-checks' className='full contact-checks-errors'>{inputErrors?.['secondContact-checks']}</div>
                </div>
                <div className={`company-details-button-container grid full`}>
                {newCompany && <Button
                    classes={`red quarter`}
                    disabled={disabled}
                    onClick={onCancel}
                    >{t.close}
                </Button>}
                {permitted && <Button
                    classes={`green last quarter`}
                    type='submit'
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                    >{t.submit}
                </Button>}
            </div>
            </Card>}
        </Page>
    );
};

export default CompanySettings;
