import { deepCopy, getNested, 
         isValid, sortList, 
         toCurrency, toCurrencyDecimal, 
         toCurrencyWhole } from 'components/shared/componentUtils';
import {text as t} from 'shared/text';

export const addCellClasses = (prop, layout, content, key) => {
    const sizes = layout.sizes;
    const format = layout.formats?.[prop];
    const hide = layout.hideColumns;
    const tooltips = layout.tooltips;
    let classes = layout.layouts?.[prop] ? layout.layouts[prop] : '';
    if (content === t.nullVal) { classes += (' placeholder') }
    if (layout.hideSortArrows?.[prop]) { classes += ' hide-sort' }
    if (format === 'currency' || format === 'currency-decimal' || format === 'currency-whole') {
        if (typeof content === 'string' && content.includes('-')) {classes += (' cell-negative')};
    }
    if (key) {
        if (sizes?.[key][prop]) { classes += ' ' + sizes[key][prop] };
        if (hide?.[key][prop]) { classes += (' hide-cell-' + hide[key][prop]) }
        if (tooltips?.[key][prop]) { classes += (' tooltip-cell' + tooltips[key][prop]) }

    } else {
        if (sizes?.[prop]) { classes += ' ' + sizes[prop] };
        if (hide?.[prop]) { classes += (' hide-cell-' + hide[prop]) }
        if (tooltips?.[prop]) { classes += (' tooltip-cell') }
    }
    return classes;
}

export const addLinkProps = (value, props, data) => {
    const length = props.length;
    for (let i = 0; i < length; i++) {
        value = value + '/' + getNested(props[i], data);
    }
    return value;
}

export const buildSpacer = (size, type, key) => {
    return <div key={key} className={`${type === 'header' ? 'table-header-cell' : 'table-cell space'} ${size}`}/>
}

export const clearSelected = (id) => {
    const old = document.getElementById(id)?.getElementsByClassName('selected')?.[0];
    if (old) { old.classList.remove('selected') }
}

export const formatContent = (content, prop, layout, filter) => {
    if (layout?.customFormats?.[prop]) {
        return layout.customFormats[prop](content);
    }
    const country = layout.country;
    const formats = layout.formats;
    if (!isValid(content)) {return t.nullVal};
    if (typeof content === 'boolean') {
        content = content ? 'true' : 'false';
    }
    const type = formats?.[prop];
    const length = content?.length;
    switch (type) {
        case 'billType':
            return '$' + content;
        case 'currency':
            return toCurrency(content, country);
        case 'currency-decimal':
            return toCurrencyDecimal(content, country);
        case 'currency-whole':
            return toCurrencyWhole(content, country);
        case 'frequency' :
            return isValid(content) ?  `1 in ${content.toFixed(2)}`: '';
        case 'icon':
            if (filter) {
                return content;
            } else {
                const icon = formats[prop + 'Icon'];
                return <i className={icon ? icon : content}></i>
            }

        case 'indicator':
            if (content === 'true'){
                return <div className='table-indicator on'></div>
            } else {
                return <div className='table-indicator'></div>
            }
        case 'lastFour':
            return '*' + content.slice(length - 4, length);
        case 'number':
            return content.toLocaleString('en-US');
        case 'percentage' :
            return isValid(content) ? content.toFixed(2) + '%': '';
        case 'standard-date':
            const date = new Date(content);
            const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        case 'transactionType':
            return getTransactionType(content);
        case 'hidden-reference':
            return showNullVal(content) || content === 0 ? t.nullVal : content;
        default:
            return showNullVal(content) ? t.nullVal : content
    }
}

const getTransactionType = (content) => {
    switch (content) {
        case 0:
            return t.notSet
        case 1:
            return t.purchase
        case 2:
            return t.refund
        case 3:
            return t.releaseEscrow
        case 4:
            return t.noPurchaseNecessary
        case 5:
            return t.redemption;
        case 6:
            return t.promo;
        default:
            return content
    }
}

export const getDisplayedRows = (id, tableStates, displayData) => {
    const states = tableStates[id];
    const rowCount = states.rowCount;
    const currentPage = states.currentPage[0];
    const rows = displayData.slice(( rowCount * (currentPage-1)), (rowCount * currentPage));
    return rows;
}

export const getMax = (prop, data) => {
    const length = data.length;
    let count = 0;
    for (let i = 0; i < length; i++) {
        const itemCount = data[i][prop].length;
        if (itemCount > count) { count = itemCount };
    }
    return count;
}

export const runFilters = (id, data, layout, tableStates) => {
    const filters = tableStates?.[id]?.filters;
    const input = filters?.inputValue;
    const orderSort = filters?.orderSort || layout.defaultSort || null;
    let newData = deepCopy(data);
    input?.value && (newData = filterByInput(newData, input.value, layout));
    orderSort && (newData = orderTableData(newData, orderSort, layout));
    return newData;
}

const filterByInput = (data, string, layout) => {
    if (!string || string.length === 0) { return data};
    string = string.trim();
    const newList = []
    const length = data.length;
    const keys = Object.keys(layout.tableHeaders);
    const keyLength = keys.length;
    for (let i = 0; i < length; i++) {
        const item = data[i];
        for (let j = 0; j < keyLength; j++) {
            const key = keys[j];
            const value = item[key];
            if (isValid(value) && foundMatch(string, value)) {
                newList.push(data[i]);
                break;
            } else {
                continue;
            }
        }
    }
    return newList;
}

const orderTableData = (data, orderSort, layout) => {
    const prop = orderSort.prop
    const value = orderSort.value;
    const defaultSort = layout.defaultSort;
    if (!value) {
        if (defaultSort){
            return sortList(data, defaultSort.prop, defaultSort.desc)
        } else {
            return sortList(data, 'id', false)
        }
    } else if (value === 'arrow-down') {
        return sortList(data, prop, true);
    } else if (value === 'arrow-up') {
        return sortList(data, prop, false);
    }
}

const foundMatch = (string, value) => {
    if (value.toString().toUpperCase().includes(string.toUpperCase())) {
        return true;
    } else {
        return false;
    }
}

export const showNullVal = (value) => {
    if (value === '' || value === null || value === undefined ) {
        return true;
    } else {
        return false;
    }
}

export const updateFilters = (newStates, setter) => {
    setter(newStates);
}
